body {
  background-color: #000;
  color: #42f445;
  font-family: Courier New, Courier, monospace;
  font-size: 15px;
}

.psst, .view-source {
  font-size: 10px;
}

@media (min-width: 800px) {
  body {
    font-size: 19px;
  }
  .psst, .view-source {
    font-size: 12px;
  }
  #letsfocus {
    height: 19px;
  }
}

@keyframes blinking {
  0% {
    opacity: 0;
  }
  19% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.cursor {
  display: inline-block;
  background-color: #42f445;
  width: .5em;
  height: 1em;
  animation: blinking .5s linear 0s infinite;
  vertical-align: middle;
}

.inline {
  display: inline;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-wrap;
  width: 100%;
  max-width: 100%;
  overflow-wrap: break-word;
}

#console {
  margin-top: 1em;
}

#console .interactive {
  line-height: 1em;
}

#letsfocus {
  opacity: 0;
  height: 15px;
}

a, a:visited {
  color: #42f445;
}

.color-1 {
  color:#ff14d7;
}

.color-1 .cursor {
  background-color:#ff14d7;
}

.color-1 a, .color-1 a:visited {
  color:#ff14d7;
}

.color-2 {
  color:#fbff14;
}

.color-2 .cursor {
  background-color:#fbff14;
}

.color-2 a, .color-2 a:visited {
  color:#fbff14;
}

.color-3 {
  color:#14fff7;
}

.color-3 .cursor {
  background-color:#14fff7;
}

.color-3 a, .color-3 a:visited {
  color:#14fff7;
}

.color-4 {
  color:#ff1414;
}

.color-4 .cursor {
  background-color:#ff1414;
}

.color-4 a, .color-4 a:visited {
  color:#ff1414;
}
